import React, {useState, useEffect} from "react";

import { BsTwitter, BsLinkedin } from "react-icons/bs";
import { AiFillGithub } from "react-icons/ai";
import { client} from "../sanityClient"


const SocialMedia = () => {
  const socialLinksQuery = '*[_type == "socials"]';
  const [socialLinks, setSocialLinks] = useState([])
  useEffect(() => {
    client.fetch(socialLinksQuery).then(data => {
      setSocialLinks(data)
      console.table(data)
    })
    // setSocialLinks([{
    //   linkedin: "https://www.linkedin.com/in/isaac-jandalala",
    //   twitter: "htpps://www.twitter.com/ijay101",
    //   github :"htpps://www.github.com/isaac1m"
    // }])

  }, [])
  
  return (
    <div className="app__social">
    {
        socialLinks.map(link => (
          <div key={link._rev}> 
          <a  href={link.url}>
          {link.icon }
          </a>
          
        </div>
        ))
    }
 
    </div>
  );
};

export default SocialMedia;
